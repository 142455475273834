@import '../../../styles/global.scss';

.nav-component {
  background: celledColors('blue', 600);
  padding: 5px 0;
  transition: all 0.5s ease;
  z-index: 1000;

  @include mq($from: mobile) {
    padding: 8px 0;
  }

  @include mq($from: tablet) {
    padding: 20px 0;
  }

  .svs & {
    background: $color-svs-red;
  }

  .cdss & {
    background: $color-cdss-blue;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__list {
    display: flex;
    padding: 0;
    margin: 0;
    direction: ltr;
  }

  &__list-item {
    list-style: none;
    padding: 0;
    margin: 0 0 0 2px;

    &:first-child {
      margin: 0;
    }

    @include mq($from: tablet) {
      margin: 0 5px;
    }
  }

  &__logo-link {
    display: flex;

    & > svg {
      height: 24px;
      width: auto;

      @include mq($from: mobile) {
        height: 40px;
        width: auto;
      }

      @include mq($from: tablet) {
        height: auto;
        width: auto;
      }

      @include mq($from: desktop) {
        height: 38px;
        width: auto;
      }
    }
  }

  &__link {
    @include font-body-12;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
    color: celledColors('white');
    padding: 0 8px;
    border-radius: 6px;
    height: 24px;

    @include mq($from: mobile) {
      height: 33px;
      border-radius: 8px;
      padding: 0 12px;
      gap: 6px;
    }

    &:hover,
    &:focus-visible {
      background-color: celledColors('blue', 500);

      .svs & {
        background-color: $color-svs-red-dark;
      }

      .cdss & {
        background-color: $color-cdss-blue-dark;
      }
    }

    &:visited {
      color: celledColors('white');
    }

    &.active {
      background-color: celledColors('blue', 500);

      .svs & {
        background-color: $color-svs-red-dark;
      }

      .cdss & {
        background-color: $color-cdss-blue-dark;
      }
    }
  }

  &__link-text {
    @include mq($from: mobile) {
      // margin-left: 6px;
    }
  }

  &__link-icon {
    @include mq($until: mobile) {
      display: none;
    }
  }
}
