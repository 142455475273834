.login-container,
.reset-password-container,
.confirmation-page-container {
  @include container;
  @include row;
  display: flex;
  position: relative;
  width: 100%;
  background: celledColors('white');
  flex-grow: 1;
  color: celledColors('grey', 700);
  overflow-y: scroll;
  flex-direction: column;

  .form-horizontal {
    max-width: 335px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    position: relative;
  }

  .form-input {
    @include font-body-14;
    padding: 15px;
    height: auto;
    border: 1px solid $color-border;
    box-shadow: none;
    border-radius: 6px;
    color: celledColors('grey', 700);
    width: 100%;
  }

  .form-input--error {
    border-color: celledColors('red', 600);
  }

  .form-input--password {
    max-width: 335px;
    margin: 0 auto;
    display: block;
  }

  .form-input__show-password-button {
    @include reset-button;
    position: absolute;
    right: 10px;
    top: 16px;
  }

  .form-info-button {
    @include reset-button;
    margin-left: 10px;
  }

  .login-reset-password {
    @include font-body-14;
    text-align: center;
  }

  .button-link {
    @include button-link;

    .main--reset_password & {
      margin: 20px auto 0;
      display: inline-block;
    }
  }

  .login-page-header,
  .reset-password-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;

    .reset-password-step-count {
      @include font-label-14;
      margin: 0;
    }
  }

  .signup-hint {
    margin: 10px auto 0;
    display: block;
    color: celledColors('red', 600);
    max-width: 335px;
  }

  .app-full-h {
    width: 100%;
    height: 100%;
  }

  .onboarding-form {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    .button {
      @include button(secondary);
      width: 100%;
      max-width: 335px;
      display: block;
      margin: 0 auto 10px;
    }

    &__footer {
      padding-bottom: 20px;

      @include mq($from: mobile) {
        min-height: 180px;
        padding-bottom: 10px;
      }
    }
  }

  .onboarding-form--reset-password-confirm-code {
    padding: 20px;
  }

  .form-title {
    margin: 20px 0;
    @include font-h2;
    color: celledColors('blue', 600);
    text-align: center;

    @include mq($from: mobile) {
      margin: 35px 0 20px;
    }

    @include mq($from: tablet) {
      @include font-h1;
    }

    &.welcome {
      @include mq($from: mobile) {
        margin: 75px 0 20px;
      }
    }
  }

  .form-subtitle {
    @include font-label-14;
    text-align: center;
    margin: 0 0 20px;

    @include mq($from: tablet) {
      @include font-label-16;
    }
  }

  .form-description {
    @include font-body-14;
    margin: 0 0 20px;
    text-align: center;

    @include mq($from: tablet) {
      @include font-body-16;
    }
  }

  .form-box {
    margin-top: 20px;

    & + .form-box {
      margin-top: 20px;
    }
  }

  .react-tel-input {
    @include border;
    width: 100%;
    max-width: 335px;
    margin: 0 auto;
    display: flex;
    border-radius: 6px;
    padding: 0;

    .dial-code-input,
    .flag-dropdown {
      @include font-body-14;
      display: flex;
      position: relative;
      align-items: center;
    }

    .dial-code-input {
      top: 0;
      left: 0;
      padding: 18px 0 0 8px;
      display: none;
    }

    .form-control {
      @include font-body-14;
      padding: 15px;
      height: auto;
      border: none;
      box-shadow: none;
      border-radius: 0 6px 6px 0;
      color: celledColors('grey', 700);
    }

    .flag-dropdown {
      border: none;
      @include border(right);
      order: -1;
      border-radius: 6px 0 0 6px;

      &.open-dropdown {
        border-radius: 6px 0 0 6px;

        .selected-flag {
          border-radius: 6px 0 0 6px;
        }
      }
    }

    .selected-flag {
      height: 100%;
      border-radius: 6px 0 0 6px;
    }

    .country-list {
      top: 50px;
      max-width: 335px;
    }
  }

  .form-gap {
    flex-grow: 1;
  }

  .form-button-view {
    text-align: center;
    margin: 20px 0;

    .button {
      @include button;
      width: 100%;

      @include mq($until: tablet) {
        width: 100%;
        max-width: 335px;
      }

      @include mq($from: tablet) {
        width: auto;
      }
    }
  }

  .react-tel-input--error {
    .form-control {
      box-shadow: inset 0 0 0 1px celledColors('red', 600);
    }
  }

  .notificationStyles {
    @include font-body-14;
    font-style: italic;
    border: 1px solid celledColors('red', 600);
    background-color: rgb(250, 179, 174);
    border-radius: 6px;
    padding: 8px;
    margin: 10px auto 0;
    max-width: 335px;
    width: 100%;
    display: flex;
    align-items: center;

    &__icon {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }
  }

  .login-new-learner-link {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;

    a {
      color: celledColors('grey', 600);

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.back-button,
.back-button-link {
  @include reset-button;
  @include font-body-14;
  display: flex;
  align-items: center;
  border-bottom: 1px solid celledColors('white');
  transition: all 0.3s ease-in;

  &__icon {
    height: auto;
    width: 10px;
    margin-right: 6px;
  }

  &:hover,
  &:focus-visible {
    border-color: celledColors('grey', 700);

    .back-button__icon {
      margin-right: 3px;
    }
  }
}

.back-button-link {
  text-decoration: none;
}

.comment.form-item {
  @include font-body-14;
  display: block;
  text-align: center;
}

.signupForm {
  .login-page-buttons {
    text-align: center;
    margin-top: 20px;

    button {
      @include button;
    }
  }
}

.confirmation-page-container {
  padding-bottom: 20px;
}

.signup-check-box {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  .signup-check-box__label {
    @include font-body-14;
    width: 100%;
    max-width: 335px;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background: celledColors('blue', 300);
    }

    /* When the checkbox is checked, add a blue background */
    .signup-check-box__input:checked ~ .signup-check-box__checkmark {
      background-color: celledColors('blue', 600);
    }

    /* Show the checkmark when checked */
    .signup-check-box__input:checked ~ .signup-check-box__checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .signup-check-box__checkmark:after {
      left: 6px;
      top: 2px;
      width: 6px;
      height: 11px;
      border: solid celledColors('white');
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  /* Hide the browser's default checkbox */
  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:focus-visible {
      & ~ .checkmark {
        outline: 2px solid -webkit-focus-ring-color;
      }
    }
  }

  /* Create a custom checkbox */
  &__checkmark {
    @include border;
    border-color: celledColors('blue', 600);
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: celledColors('white');

    /* Create the checkmark/indicator (hidden when not checked) */
    &:after {
      content: '';
      position: absolute;
      display: none;
    }
  }
}

.form-terms {
  @include font-body-14;
  text-align: center;
}
