@import '../../../styles/global.scss';

.timeout-modal {
  text-align: center;

  .timeout-title {
    @include font-h3;
  }

  .timeout-subtitle {
    @include button;
    margin: 0 auto;
  }
}
