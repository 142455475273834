@import '../../../styles/global.scss';

.progress-bar-container {
  flex-grow: 1;
  height: 15px;
  position: relative;
  cursor: pointer;
  margin: 0;

  &::before {
    content: '';
    height: 5px;
    width: 100%;
    position: absolute;
    top: 5px;
    background-color: celledColors('blue', 300);
    border-radius: 5px;
  }

  &__progress {
    cursor: pointer;
    height: 5px;
    background: celledColors('green', 500);
    position: absolute;
    top: 5px;
    border-radius: 5px 0 0 5px;
  }

  &__handle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: celledColors('green', 500);
    display: block;
  }
}
