@import '../../../styles/global.scss';

.signup-confirmation-form {
  margin-top: 35px;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &__confirmation-input {
    @include font-body-16;
    @include border;
    width: 50px;
    height: 55px;
    border-radius: 6px;
    margin: 0 0 10px 10px;
    text-align: center;
    -webkit-outer-spin-button: none;
    -webkit-appearance: none;

    &:first-child {
      margin-left: 0;
    }

    &:focus {
      outline: 1px solid celledColors('green', 500);
    }
  }

  &__button {
    @include button;
    width: 100%;
    margin: 0 auto;

    @include mq($until: tablet) {
      width: 100%;
      max-width: 335px;
    }

    @include mq($from: tablet) {
      width: auto;
    }
  }
}
