@import '../../../styles/global.scss';

.reconnect-wrapper {
  @include font-label-14;
  background: celledColors('blue', 300);
  padding: 10px;
  display: flex;
  justify-content: center;

  .reconnect-labels {
    display: flex;
    flex-wrap: wrap;
  }

  .countdown {
    margin: 0 0 0 8px;
  }

  .reconnect-button {
    @include reset-button;

    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
