// Cell-Ed Theme
@import '@celled/lms.utils.colors/celledColors.scss';

// SVS Colors
$color-svs-red: #990000;
$color-svs-red-dark: #820000;

// CDSS colors
$color-cdss-blue: #003660;
$color-cdss-blue-dark: #001c44;

// App Colors
$color-gray-light: #ddd;
$color-border: $color-gray-light;

// // Fonts
$color-font: celledColors('grey', 700);
