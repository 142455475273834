@import '../../../styles/global.scss';

.login-footer {
  display: flex;
  justify-content: center;

  &__help-link {
    @include font-body-14;
  }
}
