@import '../../../../styles/global.scss';

.multiple-choice-heading {
  @include font-body-12;
  text-align: center;
  margin: 20px 0 20px;
}

.multiple-choice-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  .multiple-choice-option {
    @include reset-button;
    @include font-body-14;
    background: celledColors('green', 400);
    color: celledColors('grey', 700);
    padding: 12px 20px;
    border-radius: 8px;
    margin: 0 8px 8px;
    text-align: center;

    &:hover {
      background: celledColors('green', 500);
    }

    // @include mq($from: mobile) {
    //   width: auto;
    //   min-width: auto;
    // }

    &__single {
      width: 100%;

      @include mq($from: desktop) {
        max-width: 50%;
      }
    }
  }
}

.multiple-choice {
  &__select {
    @include font-body-14;
  }

  .react-select {
    &__control {
      padding: 10px 6px;
    }

    &__control--is-focused {
      border-color: celledColors('blue', 500);
    }

    &__indicator-separator {
      display: none;
    }

    &__option {
      &:hover {
        background-color: celledColors('green', 400);
      }
    }

    &__option--is-focused {
      background-color: celledColors('green', 400);
    }
  }
}
