// Transition styles
.slide-up {
  &-enter {
    opacity: 0;
    transform: translateY(20px);
  }

  &-enter-active,
  &-enter-done {
    transform: translateY(0);
    opacity: 1;
  }

  &-exit {
    transform: translateY(0);
    opacity: 1;
  }

  &-exit-active,
  &-exit-done {
    opacity: 0;
    transform: translateY(20px);
  }
}

.page {
  &-enter {
    opacity: 0;
    transform: translateY(40px);
    height: 100%;
  }

  &-enter-active,
  &-enter-done {
    transform: translateY(0);
    opacity: 1;
    height: 100%;
    transition: all 0.4s ease-in;
  }

  &-exit {
    opacity: 1;
    transform: translateY(0);
    height: 0%;
  }

  &-exit-active,
  &-exit-done {
    opacity: 0;
    transform: translateY(-40px);
    height: 0%;
    transition: all 0.4s ease-in;
  }
}
