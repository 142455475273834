@import '../../../styles/global.scss';

.course-list-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .options-list-topic {
    @include reset-button;
    @include font-label-14;
    background: celledColors('blue', 500);
    color: celledColors('white');
    padding: 10px;
    width: calc((100% - 10px) / 2);
    border-radius: 8px;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: celledColors('blue', 600);
    }

    @include mq($from: desktop) {
      width: calc((100% - 30px) / 4);
    }
  }
}

.cl-elements-container {
  @include mq($from: desktop) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mq($from: wide) {
      justify-content: flex-start;
    }
  }

  .cl-element-container {
    @include reset-button;
    @include border;
    background: celledColors('white');
    color: celledColors('grey', 700);
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    width: 100%;
    align-items: center;
    position: relative;
    text-align: left;

    @include mq($from: desktop) {
      width: calc((100% - 20px) / 2);
    }

    @include mq($from: wide) {
      width: calc((100% - 40px) / 3);
      margin-right: 20px;

      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }

    &:hover {
      border-color: celledColors('black');
    }

    &.new {
    }

    &.cl-active-element {
      margin-top: 18px;
      border: 2px solid celledColors('yellow', 600);

      @include mq($from: desktop) {
        margin-top: 8px;
      }
    }

    &.cl-active-element-rtl {
      direction: rtl;
    }

    .course-details-progress {
      background-color: celledColors('blue', 300);
      border-radius: 15px;
      overflow: hidden;
    }

    .progress-bar-labels {
      width: 100%;
      text-align: center;
      float: left;
      padding-top: 1px;
    }
  }

  .cl-element-title-container {
    @include font-body-14;
  }

  .cl-element-progressbar {
    width: 120px;
    direction: ltr;

    .progress-bar-container {
      height: 20px;
      border-radius: 10px;
      background-color: $color-border;
      overflow: hidden;
    }

    .progress-bar-progress {
      height: 20px;
      border-radius: 10px;
      background: celledColors('green', 400);

      &::after {
        display: none;
      }
    }
    .progress-bar-labels {
      @include font-body-12;
      z-index: 2;
      position: relative;
      line-height: 20px;
    }
  }

  .cl-course-active {
    @include font-body-10;
    background: celledColors('yellow', 600);
    padding: 3px 10px;
    border-radius: 10px;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .cl-element-action {
    @include font-label-12;
  }
}

.course-groups-subtopics-container {
  @include container;
  @include row;
  background: celledColors('white');
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .course-groups-subtopics-header {
    margin-bottom: 40px;
  }

  .subtopics-page-title {
    @include font-h4;
    text-align: center;
  }

  &__footer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    padding-top: 20px;
    background: celledColors('white');
  }

  &__footer-image {
    width: 302px;
    height: auto;
  }
}
