$font-display: 'Montserrat', sans-serif;
$font-body: 'Open Sans', sans-serif;

$tiny-mobile: 250px;
$mobile: 251px;
$tablet: 768px;
$desktop: 992px;
$wide: 1200px;

$mq-breakpoints: (
  tiny-mobile: $tiny-mobile,
  mobile: $mobile,
  tablet: $tablet,
  desktop: $desktop,
  wide: $wide
);
