@import '../../../styles/global.scss';

.play-button {
  @include reset-button;
  margin-right: auto;
  height: 36px;
  width: 36px;

  > svg {
    height: auto;
    width: 100%;
  }

  @include mq($from: mobile) {
    height: 44px;
    width: 44px;
    margin-right: 0;
  }

  @include mq($from: tablet) {
    height: 56px;
    width: 56px;
  }

  &__loading {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 8px celledColors('red', 500);
    margin: 8px;
    border: 3px solid rgba(255, 255, 255, 0.2);
    border-top-color: rgb(255, 255, 255);
    animation: spin 1s infinite linear;
    background: celledColors('red', 500);
    overflow: hidden;

    @include mq($from: mobile) {
      width: 24px;
      height: 24px;
      box-shadow: 0 0 0 10px celledColors('red', 500);
      margin: 10px;
      border: 4px solid rgba(255, 255, 255, 0.2);
      border-top-color: rgb(255, 255, 255);
    }

    @include mq($from: tablet) {
      height: 35px;
      width: 35px;
    }
  }

  &.disable {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.glowing {
    animation: glowingButton 2500ms cubic-bezier(0, 0.21, 0.76, 1.1) infinite;
    animation-delay: 3s;
  }

  &.overlay {
    z-index: 999;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
