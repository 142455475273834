@import '../../../styles/global';

.toggle-container {
  &__label {
    @include font-body-16;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include mq($from: mobile) {
      @include font-body-18;
    }
  }

  &__toggle {
    @include reset-button;
    @include border(bottom);
    @include row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    background: celledColors('white');
  }

  &__icon {
    margin-right: 6px;
    display: flex;
    align-items: center;
    width: 15px;
    justify-content: center;
  }

  &__content {
    @include row;
    background: #f1f1f1;
    max-height: 0;
    opacity: 0;
    transition: all 0.5s ease-in;
    overflow: hidden;
    visibility: hidden;
  }
}

.toggle-container__content--show {
  @include border(bottom);
  max-height: 100%;
  opacity: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  visibility: visible;
}
