.gral-celled-spinner {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.base {
  position: relative;
  pointer-events: none;
  width: 100%;
}
.dots {
  display: grid;
  position: absolute;
  grid-template-columns: repeat(3, 1fr);
  gap: 19%;
  width: 30%;
  height: 30%;
  top: 59.5%;
  left: 34%;
}
.dot-gral {
  opacity: 0;
  border-radius: 20%;
  background-color: black;
  width: 130%;
  height: 130%;
}
@media (prefers-reduced-motion: no-preference) {
  .dot1 {
    animation: dot1 infinite 4s ease-in-out;
  }
  .dot2 {
    animation: dot1 infinite 4s linear;
    -webkit-animation-delay: 0.22s;
    -moz-animation-delay: 0.22s;
    animation-delay: 0.22s;
  }
  .dot3 {
    animation: dot1 infinite 4s linear;
    -webkit-animation-delay: 0.44s;
    -moz-animation-delay: 0.44s;
    animation-delay: 0.44s;
  }
  .dot4 {
    animation: dot1 infinite 4s linear;
    -webkit-animation-delay: 0.66s;
    -moz-animation-delay: 0.66s;
    animation-delay: 0.66s;
  }
  .dot5 {
    animation: dot1 infinite 4s linear;
    -webkit-animation-delay: 0.99s;
    -moz-animation-delay: 0.99s;
    animation-delay: 0.99s;
  }
  .dot6 {
    animation: dot1 infinite 4s linear;
    -webkit-animation-delay: 1.21s;
    -moz-animation-delay: 1.21s;
    animation-delay: 1.21s;
  }
  .dot7 {
    animation: dot1 infinite 4s linear;
    -webkit-animation-delay: 1.43s;
    -moz-animation-delay: 1.43s;
    animation-delay: 1.43s;
  }
  .dot8 {
    animation: dot1 infinite 4s linear;
    -webkit-animation-delay: 1.65s;
    -moz-animation-delay: 1.65s;
    animation-delay: 1.65s;
  }
  .dot9 {
    animation: dot1 infinite 4s linear;
    -webkit-animation-delay: 1.87s;
    -moz-animation-delay: 1.87s;
    animation-delay: 1.87s;
  }
}
@keyframes dot1 {
  0% {
    opacity: 0;
  }
  11% {
    opacity: 1;
  }
}
@keyframes dot2 {
  11% {
    opacity: 0;
  }
  22% {
    opacity: 1;
  }
}
@keyframes dot3 {
  22% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
}
@keyframes dot4 {
  33% {
    opacity: 0;
  }
  44% {
    opacity: 1;
  }
}
@keyframes dot5 {
  34% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
}
@keyframes dot6 {
  44% {
    opacity: 0;
  }
  66% {
    opacity: 1;
  }
}
@keyframes dot7 {
  55% {
    opacity: 0;
  }
  77% {
    opacity: 1;
  }
}
@keyframes dot8 {
  66% {
    opacity: 0;
  }
  88% {
    opacity: 1;
  }
}
@keyframes dot9 {
  77% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
