@use 'sass:math';

// resets
@mixin reset-button {
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;
  color: celledColors('grey', 700);

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
  }
}

@mixin reset-ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

// font px conversion to rem
@function remCalc($size) {
  $remSize: math.div($size, 16px);
  @return #{$remSize}rem;
}

@mixin remCalc($size) {
  font-size: $size; // fallback in px
  font-size: remCalc($size);
}

// layout
@mixin container {
  transition: all 0.5s ease;
  width: 100%;

  @include mq($from: tablet) {
    max-width: $tablet;
    margin: 0 auto;
  }

  @include mq($from: desktop) {
    max-width: $desktop;
  }

  @include mq($from: wide) {
    max-width: $wide;
  }
}

@mixin row {
  padding-left: 10px;
  padding-right: 10px;

  @include mq($from: mobile) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mq($from: desktop) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

// Border Mixins
@mixin border($sides: '') {
  @if ($sides == '') {
    border: solid 1px $color-border;
  } @else {
    @each $side in $sides {
      @if ($side == 'top' or $side == 'right' or $side == 'bottom' or $side == 'left') {
        border-#{$side}: solid 1px $color-border;
      }
    }
  }
}

// Typography Mixins
@mixin font-h1 {
  font: 400 remCalc(48px) / 1.2 $font-display;
}

@mixin font-h2 {
  font: 400 remCalc(36px) / 1.2 $font-display;
}

@mixin font-h3 {
  font: 400 remCalc(24px) / 1.2 $font-display;
}

@mixin font-h4 {
  font: 400 remCalc(20px) / 1.2 $font-display;
}

@mixin font-h5 {
  font: 400 remCalc(16px) / 1.2 $font-display;
}

@mixin font-h6 {
  font: 400 remCalc(14px) / 1.2 $font-display;
}

@mixin font-body-18 {
  font: 400 remCalc(18px) / 1.3 $font-body;
}

@mixin font-body-16 {
  font: 400 remCalc(16px) / 1.3 $font-body;
}

@mixin font-body-14 {
  font: 400 remCalc(14px) / 1.3 $font-body;
}

@mixin font-body-12 {
  font: 400 remCalc(12px) / 1.3 $font-body;
}

@mixin font-body-10 {
  font: 400 remCalc(10px) / 1.3 $font-body;
}

@mixin font-label-16 {
  font: 700 remCalc(16px) / 1.3 $font-body;
}

@mixin font-label-14 {
  font: 700 remCalc(14px) / 1.3 $font-body;
}

@mixin font-label-12 {
  font: 700 remCalc(12px) / 1.3 $font-body;
}

@mixin font-label-10 {
  font: 700 remCalc(10px) / 1.3 $font-body;
}
