@import '../../../styles/global.scss';

.achievements {
  &__switch {
    position: relative;
  }

  &__check-input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    &:checked {
      ~ .achievements__check-label {
        &::before {
          left: 50%;
        }
      }
    }
  }

  &__check-label {
    display: flex;
    border: 1px solid $color-border;
    border-radius: 6px;
    background-color: celledColors('white');
    max-width: 335px;
    margin: 0 auto 16px;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      background: celledColors('green', 400);
      display: block;
      width: 50%;
      height: 100%;
      transition: all 0.3s ease-in-out;
    }
  }

  &__check-label-item {
    width: 50%;
    padding: 10px;
    z-index: 1;
    position: relative;
    text-align: center;
  }

  &__check-label-title {
    @include font-label-14;
    display: block;
    text-transform: capitalize;
  }

  &__check-label-subtitle {
    font-size: 12px;
  }

  .achievements-list {
    margin: 0;
    padding: 0;

    &__list-item {
      display: block;
      background-color: celledColors('white');
      border: 1px solid $color-border;
      margin: 0 auto 20px;
      overflow: hidden;
      text-align: center;
      width: 100%;
      max-width: 100%;
      transition: all 0.2s ease-in;

      @include mq($from: tablet) {
        max-width: 335px;
      }

      @include mq($from: desktop) {
        max-width: 290px;
      }
    }

    &__title {
      @include font-label-14;
      background: celledColors('yellow');
      padding: 8px;
      margin: 0;
    }

    &__value {
      font: 700 remCalc(40px) / 1 $font-body;
      padding: 20px;

      @include mq($from: desktop) {
        padding: 30px 20px;
      }
    }

    &__array-content {
      min-height: 150px;
      padding: 0 20px 20px;

      .achievements-list__value {
        padding: 20px 20px 10px;
      }
    }

    &__icon-certificate {
      width: 100px;
      margin: 20px 0;
    }
  }

  .achievements-certs-carousel {
    margin: 10px 0 20px;

    .slick-prev {
      left: 0;
      z-index: 1;

      &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid black;
      }
    }

    .slick-next {
      right: -10px;
      z-index: 1;

      &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid black;
      }
    }

    .slick-dots li {
      width: 10px;
    }

    .slick-dots li button {
      &:before {
        font-size: 7px;
      }
    }
  }
}
