@import '../../../styles/global.scss';

.main--course_details {
  background: celledColors('grey', 200);
}

.course-details-container {
  @include container;
  @include row;
  background: celledColors('white');
  height: 100%;

  .course-details-wrapper {
    overflow-y: auto;
    height: 100%;
  }

  .course-details-title {
    @include font-h4;
    text-align: center;
  }

  .course-details-progress {
    margin-top: 40px;
  }

  .start-button {
    @include button;
    width: 100%;
    max-width: 335px;
    margin: 0 auto;
    display: block;
    text-align: center;
  }

  .course-details-subtitle {
    @include font-label-14;
  }

  .course-details-progress {
    text-align: center;
    margin: 40px 0;

    .progress-bar-container {
      width: 120px;
      height: 20px;
      border-radius: 10px;
      background-color: $color-border;
      margin: 0 auto 20px;
      overflow: hidden;
      position: relative;

      &::before {
        display: none;
      }
    }

    .progress-bar-progress {
      height: 20px;
      border-radius: 10px;
      background: celledColors('green', 400);
      position: absolute;
      top: 0;
    }

    .progress-bar-labels {
      @include font-body-12;
      z-index: 2;
      position: relative;
      line-height: 20px;
    }
  }

  .course-details-certificate {
    margin: 0 auto 40px;
    width: 80%;
  }

  .course-details-modal {
    &__headline {
      @include font-h4;
    }

    &__course-name {
      @include font-label-14;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .button {
      margin-top: 20px;
      @include button('secondary', small);

      @include mq($from: mobile) {
        min-width: auto;
        width: calc((100% - 10px) / 2);
      }
    }

    .cl-box-button-confirmation {
      @include button('primary', small);

      @include mq($from: mobile) {
        min-width: auto;
        width: calc((100% - 10px) / 2);
      }
    }
  }

  &__message {
    text-align: center;
    margin: 40px;
  }

  &__message-button {
    @include button('primary', small);
    margin: 0 auto 40px;
    display: block;
  }
}

.course-details-lessons-container {
  overflow-y: auto;

  @include mq($from: desktop) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .detailslessonlist {
    @include reset-button;
    @include border;
    background: celledColors('grey', 300);
    color: celledColors('grey', 700);
    display: flex;
    justify-content: flex-start;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    width: 100%;
    align-items: center;
    position: relative;
    text-align: left;

    &.detailslessonlistDisabled {
      cursor: not-allowed;
      background: celledColors('white');
    }

    @include mq($from: desktop) {
      width: calc((100% - 20px) / 2);
    }

    @include mq($from: wide) {
      width: calc((100% - 40px) / 3);
    }
  }

  .unit-number,
  .progressLessonStatus {
    @include font-label-12;
    margin: 0;
    width: 65px;
    flex-shrink: 0;
  }

  .progressLessonStatus {
    text-align: center;
  }

  .lesson-title {
    @include font-body-14;
    margin: 0;
    flex-grow: 1;
  }
}
