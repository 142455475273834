@import '../../../styles/global.scss';

.cl-certificate-container {
  position: relative;
  display: flex;
  height: 200px;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.cl-certificate-error {
  @include reset-button;
  padding: 20px 30px;

  svg {
    height: 30px;
    width: auto;
    margin-bottom: 10px;
  }

  span {
    @include font-body-14;
  }
}
.cl-certificate-image-container {
  position: relative;
  width: fit-content;
  .cl-certificate-image {
    width: 100%;
    height: auto;
  }
  .cl-certificate-download-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 33%;
    cursor: pointer;
  }
}
