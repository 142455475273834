@mixin button($type: 'primary', $size: 'large') {
  @include reset-button;
  font: 700 18px/1 $font-body;
  color: celledColors('white');
  background: celledColors('green', 500);
  padding: 16px 20px;
  border-radius: 6px;
  transition: all 0.1s ease-in;
  width: 100%;

  @include mq($from: mobile) {
    min-width: 200px;
    width: auto;
  }

  &:hover,
  &:focus-visible {
    background: celledColors('green', 600);
  }

  &:disabled,
  &[disabled] {
    background: celledColors('grey', 300);
    cursor: not-allowed;
  }

  @if $size == 'small' {
    font-size: 14px;
    min-width: 100px;
    border-radius: 8px;
  }

  @if $type == 'secondary' {
    background: celledColors('grey', 400);

    &:hover,
    &:focus-visible {
      background: celledColors('grey', 500);
    }
  }
}

@mixin button-link {
  @include reset-button;
  @include font-body-14;
  text-decoration: underline;

  &:hover,
  &:focus-visible {
    text-decoration: none;
  }
}
