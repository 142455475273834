@import '../../../../styles/global.scss';

$color-bubble-left: #f1f1f1;
$color-bubble-right: #d0ecff;

.bubble-container {
  @include font-body-14;
  word-wrap: break-word;
  padding: 10px 15px 5px;
  max-width: 80%;
  border-radius: 10px;
  position: relative;
  margin-top: 10px;

  &::before,
  &::after {
    bottom: -0.1rem;
    content: '';
    height: 1rem;
    position: absolute;
  }

  @include mq($from: tablet) {
    padding: 15px 15px 10px;
    max-width: 60%;
  }

  @include mq($from: desktop) {
    @include font-body-16;
  }

  .chat-text {
    h5 {
      @include font-h4;
      color: celledColors('grey', 700);
      margin: 0 0 8px;
    }

    p {
      margin: 0 0 8px;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  .chat-bubble-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .chat-message-info,
  .chat-message-time {
    font: 400 remCalc(9px) / 1 $font-body;
    margin: 0;
  }

  .chat-message-info {
    margin-left: 10px;
  }

  hr {
    margin: 0;
  }
}

.chat-left-bubble {
  display: flex;

  .bubble-container {
    background: $color-bubble-left;

    &::before {
      border-bottom-right-radius: 0.8rem 0.7rem;
      border-left: 16px solid $color-bubble-left;
      left: -6px;
      transform: translate(0, -0.1rem);
    }

    &::after {
      background-color: #fff;
      border-bottom-right-radius: 0.5rem;
      left: 20px;
      transform: translate(-30px, -2px);
      width: 10px;
    }
  }
}

.chat-right-bubble {
  display: flex;
  flex-direction: row-reverse;

  .bubble-container {
    background: $color-bubble-right;

    &::before {
      border-bottom-left-radius: 0.8rem 0.7rem;
      border-right: 1rem solid $color-bubble-right;
      right: -0.35rem;
      transform: translate(0, -0.1rem);
    }

    &::after {
      background-color: #fff;
      border-bottom-left-radius: 0.5rem;
      right: -40px;
      transform: translate(-30px, -2px);
      width: 10px;
    }
  }
}
