@import '../../../styles/global.scss';

.switch-flow-modal {
  &__headline {
    @include font-h4;

    @include mq($from: tablet) {
      @include font-h3;
    }
  }

  &__button {
    @include button;
    @include mq($from: mobile) {
      width: 100%;
    }

    @include mq($from: tablet) {
      width: auto;
    }
  }
}
