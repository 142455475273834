@import '../../../styles/global.scss';

.react-img-button {
  @include reset-button;
  @include button('primary', 'small');
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    margin-top: 10px;
    width: 180px;
  }
}
