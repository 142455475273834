@import '../../../styles/global.scss';

.ap-indicators {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  font-size: remCalc(12px);
}

.ap-time-markers {
  @include font-body-12;

  &.leftside {
    margin-right: 20px;
  }

  &.rightside {
    margin-left: 20px;
  }
}
