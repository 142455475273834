@import '../../../../styles/global.scss';

.chat {
  @include container;
  @include row;
  padding-top: 10px;
  background: celledColors('white', 500);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;

  @include mq($from: mobile) {
    padding-top: 20px;
    padding-bottom: 60px;
  }

  .chat-center-container {
    margin-bottom: 10px;
  }

  .header-container {
    @include font-body-12;
    background: celledColors('yellow');
    border-radius: 8px;
    text-align: center;
    padding: 6px;
    width: 100%;
    color: celledColors('black');
    margin-top: 10px;
  }

  &__prev-messages-button {
    @include reset-button;
    @include font-body-12;
    background: celledColors('blue', 300);
    border: 1px solid celledColors('green', 500);
    color: celledColors('black');
    border-radius: 8px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    margin: 0 auto;
    transition: all 0.2s ease-in;

    &:hover,
    &:focus-visible,
    &:active {
      border-color: celledColors('green', 600);
    }
  }
}

.chat-rtl {
  direction: rtl;
  text-align: right;
}

.chat-image-container {
  background-color: rgba(0, 0, 0, 100);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  align-items: center;
  z-index: 5000;
}

.chat-main-float-container {
  position: fixed;
  bottom: calc(env(safe-area-inset-bottom) + 80px);
  background: celledColors('white');
  padding: 20px;
  border-radius: 10px;
  border: 1px solid celledColors('grey', 700);
  transition: all 0.3s ease-in;
  max-width: calc(100% - 40px);

  @include mq($from: tablet) {
    max-width: calc($tablet - 40px);
  }

  @include mq($from: desktop) {
    max-width: calc($desktop - 80px);
  }

  &::after {
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    background: celledColors('white');
    position: absolute;
    bottom: -6px;
    left: 13px;
    border-right: 1px solid celledColors('grey', 700);
    border-bottom: 1px solid celledColors('grey', 700);
  }

  .chat-main-float-bubble {
    max-height: 200px;
    overflow: scroll;
  }

  .chat-main-float-element {
    @include button(primary, small);
    margin-bottom: 10px;
    width: 100%;
    display: block;

    &:last-child {
      margin-bottom: 0;
    }

    @include mq($from: tablet) {
      margin: 5px;
    }
  }
}

.chat-main-modal {
  &__headline {
    @include font-h4;
  }

  &__subhead {
    @include font-label-14;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .cl-box-button-close {
      @include button('secondary', small);
    }
    .cl-box-button-confirmation {
      @include button('primary', small);
    }

    .button {
      margin-top: 20px;

      @include mq($from: mobile) {
        min-width: auto;
        width: calc((100% - 10px) / 2);
      }
    }
  }
}
.chat-multimedia {
  .chat-emoticon {
    font-size: 5em;
    padding-top: 10px;
    line-height: 1;
  }
}
