@import '../../../styles/global.scss';

.resource-main {
  @include font-body-14;

  .resource-main-section {
    margin-bottom: 30px;
  }

  .resource-title {
    @include font-label-14;
    color: celledColors('black');
    margin-bottom: 8px;
  }

  .resource-description {
    color: celledColors('black');
    margin-bottom: 10px;
    display: block;
  }

  .multimedia-web-link {
    @include reset-button;
    text-decoration: underline;
  }

  .multimedia-emoticon {
    font-size: 5em;
    padding-top: 10px;
    line-height: 1;
  }

  .asset-video {
    position: relative;
    height: 300px;
    width: 100%;
    margin-top: 10px;

    @include mq($from: tablet) {
      height: 300px;
      width: 500px;
    }

    & > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
