@import '../../../styles/global.scss';

.faqs {
  display: flex;
  flex-direction: column;

  &__container {
    margin-bottom: 30px;
  }

  &__title {
    @include font-label-14;
    margin: 0 0 6px;
  }

  &__answer {
    p {
      @include font-body-14;
      margin: 0;
    }
  }
}
