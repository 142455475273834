@import '../../../../styles/global.scss';

.input-holder {
  @include container;
  @include row;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;

  @include mq($from: tablet) {
    left: calc(50% - #{$tablet}/ 2);
  }

  @include mq($from: desktop) {
    left: calc(50% - #{$desktop}/ 2);
  }

  @include mq($from: wide) {
    left: calc(50% - #{$wide}/ 2);
  }
}

.input-pop-up {
  @include reset-button;
  background: celledColors('blue', 300);
  border: 1px solid celledColors('green', 500);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  flex-shrink: 0;

  @include mq($from: mobile) {
    height: 42px;
    width: 42px;
  }

  .icon-plus {
    transform: scale(0.8);

    @include mq($from: mobile) {
      transform: none;
    }
  }

  &:hover,
  &:focus-visible {
    border-color: celledColors('grey', 700);
  }
}

.input-container {
  @include font-body-12;
  flex-grow: 1;
  border: 1px solid celledColors('grey', 600);
  display: flex;
  padding: 3px 3px 3px 0;
  border-radius: 25px;
  background: celledColors('white');
  align-items: center;

  @include mq($from: mobile) {
    @include font-body-14;
  }

  @include mq($from: tablet) {
    padding: 3px 3px 3px 0;
  }

  &--disabled {
    border-color: $color-border;
    color: celledColors('grey', 500);
    cursor: not-allowed;
  }

  &:focus-within {
    border-color: celledColors('black');
    outline: none;
  }

  .input-main {
    border: none;
    color: celledColors('black');
    width: 100%;
    height: 100%;
    padding: 9px 0px 9px 15px;
    margin-right: 10px;
    margin-left: 2px;
    border-radius: 20px;

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }

  .input-button {
    @include reset-button;
    @include font-label-12;
    background: celledColors('green');
    color: celledColors('white');
    padding: 4px 10px;
    border-radius: 20px;

    @include mq($from: mobile) {
      @include font-label-14;
      padding: 10px 20px;
      border-radius: 20px;
    }
  }

  .input-button--disabled {
    cursor: not-allowed;
    background: $color-border;
  }

  .disable-input-box {
    flex-grow: 1;
    width: 0;
    white-space: nowrap;
    overflow-x: auto;
    margin-right: 8px;
    padding-left: 10px;

    @include mq($from: mobile) {
      width: auto;
      padding-left: 15px;
    }
  }
}
