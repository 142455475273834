// base project app wide styles

html {
  box-sizing: border-box;
  font-family: $font-body;
  color: celledColors('grey', 700);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  transition: all 0.5s ease;

  @include mq($from: tablet) {
    max-width: $tablet;
    margin: 0 auto;
  }

  @include mq($from: desktop) {
    max-width: $desktop;
  }

  @include mq($from: wide) {
    max-width: $wide;
  }
}

.row {
  padding-left: 10px;
  padding-right: 10px;

  @include mq($from: mobile) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mq($from: desktop) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

img {
  max-width: 100%;
}

.loading {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 1;
  top: 0;
  left: 0;
}

.app-full-vh {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

@function safeVH($quantity) {
  @return calc(
    (var(--vh, 1vh) * #{$quantity}) - env(safe-area-inset-top) - env(safe-area-inset-bottom)
  );
}

.app-full-vh {
  // three height fallbacks
  // (1) full-viewport height, as-is.
  height: 100vh;
  // (2) full-viewport height, minus safe area insets (Apple devices)
  // height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));

  //override: reduce excess height on safe-area devices to prevent scrolling (all iOS devices)
  // (3) full-viewport height, calculated using window.innerHeight for in-browser devices
  // height: safeVH(100);
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-top: env(safe-area-inset-top);
  // padding-bottom: env(safe-area-inset-bottom);
}

.main {
  background-color: celledColors('grey', 300);
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  margin-bottom: env(safe-area-inset-bottom);

  @include mq($from: mobile) {
    border-bottom: 10px solid celledColors('blue', 600);
  }

  .svs & {
    border-color: $color-svs-red;
  }

  .cdss & {
    border-color: $color-cdss-blue;
  }

  &--help,
  &--me {
    overflow: scroll;
  }

  & > div {
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex: 1 1;
  }
}

.visually-hidden {
  visibility: hidden;
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
}

a {
  color: celledColors('grey', 700);

  &:hover,
  &:focus-visible {
    text-decoration: none;
  }
  &:visited {
    color: inherit;
  }
}

.no-line-break {
  white-space: nowrap;
}
