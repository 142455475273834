@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.app {
  & > * {
    font-family: $font-body;
    color: $color-font;
  }

  .headline,
  .subhead,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: celledColors('blue', 600);
  }
}
