@import '../../../styles/global.scss';
@import '../../../../node_modules/react-toggle/style';

.react-toggle .react-toggle-track {
  background-color: celledColors('grey', 400) !important;
}

.react-toggle--checked .react-toggle-track {
  background-color: celledColors('green') !important;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px celledColors('green') !important;
  -moz-box-shadow: 0px 0px 5px 5px celledColors('green') !important;
  box-shadow: 0px 0px 5px 5px celledColors('green') !important;
}

.react-toggle-thumb {
  border-color: celledColors('grey', 400);
}

.react-toggle--checked .react-toggle-thumb {
  border: 1px solid celledColors('green');
}

.react-toggle--focus-visible .react-toggle-thumb {
  border-color: celledColors('green');
  box-shadow: 0px 0px 2px 3px celledColors('green');
}

.profile-main__footer {
  @include font-body-14;
  color: celledColors('black');

  dt {
    margin-bottom: 10px;
  }

  dd {
    @include font-body-12;
    margin-bottom: 10px;
    margin-left: 20px;

    a {
      color: blue;
    }
  }
}

.profile-option-container {
  @include font-body-14;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: celledColors('black');
  align-items: center;

  &__em {
    display: block;
    font-size: remCalc(10px);
    width: 250px;
  }
}
