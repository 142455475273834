@import '../../../styles/global.scss';

.notification {
  background: celledColors('blue', 300);
  position: fixed;
  width: 100%;
  bottom: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: notification 1s ease-in;
  animation-fill-mode: forwards;

  &__close-button {
    @include reset-button;
    margin-left: 10px;

    .icon-close {
      height: 15px;
      width: 15px;
    }
  }
}

@keyframes notification {
  from {
    bottom: -50px;
    opacity: 0;
  }
  to {
    bottom: 10px;
    opacity: 1;
  }
}
