@import '../../../styles/global.scss';

.cm-page-container {
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1;
  position: relative;
}

.courses-main {
  &__header {
    @include border('bottom');
    background: celledColors('white', 500);
  }

  &__header-container {
    @include mq($from: tablet) {
      @include container;
      @include row;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 16px;
      padding-bottom: 20px;
      gap: 40px;
    }

    @include mq($from: desktop) {
      padding-top: 20px;
      padding-bottom: 24px;
    }
  }

  &__page-title {
    @include font-body-14;
    @include border('bottom');
    transition: all 0.5s ease;
    padding: 14px 20px;
    margin: 0;
    display: none;

    @include mq($from: mobile) {
      display: block;
    }

    @include mq($from: tablet) {
      border: none;
      padding: 14px 0;
    }

    @include mq($from: desktop) {
      @include font-h4;
    }
  }

  &__page-title--primary {
    @include mq($from: tablet) {
      @include font-h3;
      display: block;
    }

    @include mq($from: desktop) {
      @include font-h2;
    }
  }

  &__page-title--secondary {
    @include mq($until: tablet) {
      &::before {
        content: ' - ';
      }
    }
    @include mq($from: tablet) {
      display: block;
      margin: 6px 0 0;
    }
  }

  &__page-title-button--ltr {
    @include reset-button;
    text-align: left;
    direction: unset;
  }

  &__page-title-button--rtl {
    @include reset-button;
    text-align: right;
    direction: rtl;
  }
}
