@import '../../../styles/global.scss';

.icon-close {
  width: 24px;
  height: 24px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    height: 2px;
    width: 100%;
    background: celledColors('grey', 700);
    border-radius: 5px;
    transform: rotate(0deg);
    transition: 0.2s ease-in-out;
  }

  span:nth-child(1) {
    transform: rotate(45deg);
  }

  span:nth-child(2) {
    transform: rotate(-45deg);
  }

  &:hover {
    span:nth-child(1) {
      transform: rotate(135deg);
    }

    span:nth-child(2) {
      transform: rotate(45deg);
    }
  }

  // span:nth-child(3) {
  //   left: 10px;
  //   top: 15px;
  // }

  // &:hover {
  //   span:nth-child(1),
  //   span:nth-child(3) {
  //     left: 13px;
  //   }

  //   span:nth-child(1) {
  //     top: 7px;
  //     transform: rotate(-135deg);
  //   }

  //   span:nth-child(2) {
  //     transform: rotate(0deg);
  //   }

  //   span:nth-child(3) {
  //     top: 15px;
  //     transform: rotate(-45deg);
  //   }
  // }
}
