@import '../../../styles/global.scss';

.player-controls {
  padding: 7px 10px;
  // Force direction ltr to persist even when useRTL is true.
  direction: ltr;

  @include mq($from: mobile) {
    padding: 7px 20px;
  }

  @include mq($from: tablet) {
    padding: 0;
  }

  &__main {
    display: flex;
    align-items: center;

    @include mq($from: tablet) {
      width: 360px;
      padding: 0;
    }

    @include mq($from: desktop) {
      width: 460px;
    }
  }

  &__title {
    @include font-body-14;
    display: none;

    @include mq($from: mobile) {
      display: block;
      flex-grow: 1;
      margin-left: 10px;
    }

    @include mq($from: tablet) {
      margin-left: 18px;
    }
  }

  &__nav-button {
    @include reset-button;
    @include font-body-10;
    background: celledColors('blue', 300);
    border: 1px solid celledColors('green', 500);
    border-radius: 8px;
    text-align: center;
    padding: 4px;
    width: 50px;
    line-height: 1;

    &:hover,
    &:focus-visible,
    &:active {
      border-color: celledColors('green', 600);
    }

    @include mq($from: mobile) {
      @include font-body-12;
      width: 60px;
      line-height: 1;
    }

    @include mq($from: tablet) {
      width: 70px;
      line-height: 1.3;
    }
  }

  &__nav-button--disabled {
    display: none;
  }

  &__lesson-nav {
    display: flex;
    gap: 5px;
  }

  &__nav-icon {
    display: none;

    @include mq($from: mobile) {
      display: block;
      margin: 0 auto 5px;
    }
  }

  &__sub-controls {
    margin-top: 10px;
    @include mq($from: desktop) {
      margin-top: 14px;
    }
  }

}

.player-controls-spacer {
}