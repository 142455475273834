@import '../../../styles/global.scss';

.icon-plus {
  position: relative;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;

  &__bar {
    display: block;
    height: 2px;
    width: 16px;
    background: celledColors('grey', 700);

    transition: all 0.3s ease-in;
    transform-origin: center;
  }

  &__bar--second {
    position: absolute;
    transform: rotate(-90deg);
  }

  &--is-minus {
    .icon-plus__bar--second {
      transform: rotate(0deg);
    }
  }
}
