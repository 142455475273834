@import '../../../styles/global.scss';

.me-page {
  @include container;
  background: celledColors('white');
  display: flex;
  flex-direction: column;
  height: 100%;

  &__title {
    @include font-h4;
    margin: 20px 0;
    text-align: center;

    @include mq($from: mobile) {
      margin: 36px 0 50px;
    }
  }

  &__footer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    padding-top: 20px;
    background: celledColors('white');
    position: relative;
    overflow: hidden;
  }

  &__footer-image-container {
    min-height: 234px;
    width: 100%;
    position: relative;
  }

  &__footer-image-right {
    width: 302px;
    height: auto;
    position: absolute;
    right: 110px;
    top: -130px;
    transform: scale(1.5);

    @include mq($until: tablet) {
      right: 0px;
      top: -55px;
      transform: scale(1);
    }

  }

  &__footer-image-left {
    width: 302px;
    height: auto;
    position: absolute;
    left: -130px;
    top: 92px;
    transform: scale(1.5);

    @include mq($until: tablet) {
      left: -170px;
      top: 140px;
      transform: scale(1);
    }
  }

  .toggle-container__content {
    overflow-y: auto;
  }

  &__section-logout {
    @include container;
    @include row;
  }

  .logoutButton {
    @include button;
    margin: 30px auto 20px;
    display: block;

    @include mq($until: tablet) {
      width: 100%;
      max-width: 335px;
    }
  }
}