@import '../../../styles/global.scss';

.live-coach-main {
  margin-bottom: 20px;

  p {
    @include font-body-14;
    font-weight: 700;
  }
}
