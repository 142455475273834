.icon-chevron {
  transition: all 0.3s;
  transform-origin: center;
}
.icon-chevron--up {
}
.icon-chevron--down {
  transform: rotate(-180deg);
}
.icon-chevron--right {
  transform: rotate(90deg);
}
.icon-chevron--left {
  transform: rotate(-90deg);
}
