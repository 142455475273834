@import '../../../styles/global.scss';

.help-page {
  @include container;
  background: celledColors('white');
  display: flex;
  flex-direction: column;
  height: 100%;

  &__title {
    @include font-h4;
    margin: 20px 0;
    text-align: center;

    @include mq($from: mobile) {
      margin: 36px 0 50px;
    }
  }

  &__footer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    padding-top: 20px;
    background: celledColors('white');
    position: relative;
    overflow: hidden;
  }

  &__footer-image-container {
    min-height: 234px;
    width: 100%;
    position: relative;
  }

  &__footer-image-right {
    width: 302px;
    height: auto;
    position: absolute;
    right: -154px;
    top: 105px;
    transform: scale(1.5);

    @include mq($until: tablet) {
      right: -162px;
      top: 95px;
      transform: scale(1);
    }
  }

  &__footer-image-left {
    width: 302px;
    height: auto;
    position: absolute;
    left: -181px;
    top: 143px;
    transform: scale(1.5);

    @include mq($until: tablet) {
      left: -170px;
      top: 140px;
      transform: scale(1);
    }
  }

  &__footer-heading {
    @include font-label-14;
    margin-bottom: 5px;
  }

  &__footer-subhead {
    @include font-body-14;
    margin: 0 0 50px;
  }

  &__footer-image {
    width: 302px;
    height: auto;
  }

  &__footer-button {
    @include reset-button;
    text-decoration: underline;

    &:hover,
    &:focus-visible {
      text-decoration: none;
    }
  }
}