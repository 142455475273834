@import '../../../styles/global.scss';

.login-help-form {
  @include container;
  @include row;
  background: celledColors('white');
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  padding-bottom: 40px;
}
