@import '../../../styles/global.scss';

.help-page-form {
  &__subtitle {
    @include font-h4;
  }

  p {
    @include font-body-14;
  }

  &__text-area {
    @include border();
    @include font-body-14;
    border-radius: 6px;
    min-height: 95px;
    width: 100%;
    margin-bottom: 16px;
    padding: 20px;
  }

  &__button {
    @include button;
    margin-bottom: 20px;

    @include mq($until: tablet) {
      width: 100%;
    }
  }
}
