@import '../../../styles/global.scss';

.profile-option-container .language-selector {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: celledColors('white');
  background-image: url("data:image/svg+xml;utf8,<svg fill='rgb(51,51,51)' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: center;
  border: 1px solid celledColors('grey', 700);
  color: celledColors('grey', 700);
  border-radius: 6px;
  padding: 5px 26px 5px 16px;
  text-transform: capitalize;
  height: 40px;
}

.languageButton {
  @include reset-button;
  @include font-body-14;
  color: celledColors('white');
  text-decoration: underline;
  text-transform: capitalize;

  &:hover,
  &:focus-visible {
    text-decoration: none;
  }
}
